// @ts-ignore
import CultureTeam from './CultureTeam/CultureTeam.tsx';

import './Culture.css';

function Culture() {

    return (
        <div className="culture section--dark">
            <div className="culture__title">CULTURE</div>
            <div className="culture__headline">
                We believe in investing in people and forming meaningful bonds
            </div>
            <CultureTeam></CultureTeam>
            <div className="culture__copy">
                Founded in 2023, Fortuna is at the forefront of shaping the fintech future. Our ethos is simple:
                engineer change that simplifies and enhances the lives of our clients. With each project, we
                aim to help clients navigate building high quality products - crafting clean code, introducing best practices, and
                leveraging cutting-edge technology solutions that resonate deeply with both their internal and external users. Our team is driven by a passion
                for innovation, an eye for excellence, and a commitment to pushing the boundaries of what's possible.
            </div>
        </div>
    );
}

export default Culture;
// @ts-ignore
import ExpertiseCard from "../ExpertiseCard/ExpertiseCard.tsx";
import './ExpertiseStack.css';

function ExpertiseStack() {
    let capabilities = [
        {
            title: "AUDIT",
            description: "Auditing your systems from top to bottom to help you identify both your strengths and your opportunities. Our team’s years of experience building and deploying cloud native and cryptocurrency financial products enables us to make recommendations through a holistic lens that serves your products, your processes, and your people."
        },
        {
            title: "AUGMENT",
            description: "Augment your existing systems involves patching up any gaps in best practices identified during the audit phase. We’ll also help you to refine (or build out) your monitoring and observability stacks, with a focus on tracking what actually matters and using dashboards that provide more signal than noise. We’ll help you and your team understand your data and compute flows so you can feel confident about the health of your systems and products.",
        },
        {
            title: "ARCHITECT",
            description: "Advance your engineering systems by developing tailored software solutions that address your unique challenges - whether that’s facilitating seamless refactors to improve code quality, architecting complex migrations that ensure minimal disruption while enhancing system capabilities, or building greenfield products to attack new market opportunities.",
        },
    ]

    return (
        <section className="expertise-stack">
            {capabilities.map((capability, idx) => (
                <ExpertiseCard key={idx} index={idx} title={capability.title} description={capability.description} />
            ))}
        </section>
    );

}

export default ExpertiseStack